/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateBookingDTO = {
    name: string;
    /**
     * There is if "type = travel". Send the name.
     */
    cityTravel?: string;
    /**
     * There is if "type = travel". Send the _id.
     */
    countryTravel?: string;
    /**
     * There is if "type = event"
     */
    reservationName?: string;
    additionalComment?: string;
    additionalCommentClient?: string;
    whatIncludedTalent?: string;
    whatIncludedClient?: string;
    eventType: CreateBookingDTO.eventType;
    type: CreateBookingDTO.type;
    visibility: CreateBookingDTO.visibility;
    genders: Array<'male' | 'female'>;
    /**
     * Send the _id
     */
    venue: string;
    countTalent: number;
    rateTalent: number;
    /**
     * There is if "eventType = entertainment and visibility = public"
     */
    countClient?: number;
    /**
     * There is if "eventType = entertainment and visibility = public"
     */
    rateClient?: number;
    /**
     * There is if "eventType = matchmaking"
     */
    isCharityDonation?: boolean;
    startDate: string;
    endDate: string;
    uploadPhotos?: Array<Blob>;
};

export namespace CreateBookingDTO {

    export enum eventType {
        ENTERTAINMENT = 'entertainment',
        DATE = 'date',
        MATCHMAKING = 'matchmaking',
    }

    export enum type {
        EVENT = 'event',
        TRAVEL = 'travel',
    }

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
        INVITATION = 'invitation',
    }


}

