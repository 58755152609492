/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAdminVenueDTO } from '../models/CreateAdminVenueDTO';
import type { CreateVenueDTO } from '../models/CreateVenueDTO';
import type { PageVenueListResponseDTO } from '../models/PageVenueListResponseDTO';
import type { UpdateAdminVenueDTO } from '../models/UpdateAdminVenueDTO';
import type { UpdateAdminVenueMinDataDTO } from '../models/UpdateAdminVenueMinDataDTO';
import type { VenueResponseDTO } from '../models/VenueResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VenueExperienceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Venue by Admin
     * @returns VenueResponseDTO Venue Data
     * @throws ApiError
     */
    public createAdminVenue({
        formData,
    }: {
        formData: CreateAdminVenueDTO,
    }): CancelablePromise<VenueResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/venue/admin',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `The venue type was not found.`,
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or [venue.addEditTravel, venue.accessVenue] can execute the request.`,
            },
        });
    }

    /**
     * Create Venue
     * @returns VenueResponseDTO Venue Data
     * @throws ApiError
     */
    public createVenue({
        requestBody,
    }: {
        requestBody: CreateVenueDTO,
    }): CancelablePromise<VenueResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/venue',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The venue type was not found.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Update Venue Min Data by Admin
     * @returns void
     * @throws ApiError
     */
    public updateVenueMinData({
        requestBody,
    }: {
        requestBody: UpdateAdminVenueMinDataDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/venue/admin/min-data',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or [venue.addEditTravel, venue.accessVenue] can execute the request.`,
            },
        });
    }

    /**
     * Update Venue by Admin
     * @returns VenueResponseDTO Venue Data
     * @throws ApiError
     */
    public updateAdminVenue({
        id,
        formData,
    }: {
        /**
         * Id
         */
        id: string,
        formData: UpdateAdminVenueDTO,
    }): CancelablePromise<VenueResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/venue/admin/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `The venue type was not found.`,
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or [venue.addEditTravel, venue.accessVenue] can execute the request.`,
            },
        });
    }

    /**
     * Venue Delete
     * @returns any Venue Deleted
     * @throws ApiError
     */
    public deleteVenue({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/venue/admin/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `Venue not found`,
            },
        });
    }

    /**
     * Like Venue
     * @returns any
     * @throws ApiError
     */
    public likeVenue({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/venue/{id}/like',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Venue not found`,
            },
        });
    }

    /**
     * Unlike Venue
     * @returns any
     * @throws ApiError
     */
    public unlikeVenue({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/venue/{id}/unlike',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Venue not found`,
            },
        });
    }

    /**
     * Venue List
     * @returns PageVenueListResponseDTO List of venue
     * @throws ApiError
     */
    public getVenueList({
        search,
        city,
        cities,
        types,
        experienceType,
        priceRatings,
        status,
        isOnlyLike,
        createdAtFrom,
        createdAtTo,
        kitchens,
        diningStyles,
        dressCodes,
        sort = 'createdAt',
        sortDirection = -1,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Send the _id
         */
        city?: string,
        /**
         * Send the _id
         */
        cities?: Array<string>,
        types?: Array<string>,
        experienceType?: 'event' | 'travel',
        priceRatings?: Array<'low' | 'medium' | 'high' | 'veryHigh'>,
        /**
         * Can be used by the admin.
         */
        status?: 'pending' | 'active' | 'hidden' | 'deleted',
        isOnlyLike?: boolean,
        createdAtFrom?: string,
        createdAtTo?: string,
        kitchens?: Array<string>,
        diningStyles?: Array<string>,
        dressCodes?: Array<string>,
        sort?: 'createdAt' | 'popular' | 'name',
        sortDirection?: -1 | 1,
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster. If using the "sort" option with "popular" value, there is no need to use this option.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageVenueListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/venue/list',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'types': types,
                'experienceType': experienceType,
                'priceRatings': priceRatings,
                'status': status,
                'isOnlyLike': isOnlyLike,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'kitchens': kitchens,
                'diningStyles': diningStyles,
                'dressCodes': dressCodes,
                'sort': sort,
                'sortDirection': sortDirection,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Venue Count
     * @returns number Venue Count
     * @throws ApiError
     */
    public getVenueCount({
        search,
        city,
        cities,
        types,
        experienceType,
        priceRatings,
        status,
        isOnlyLike,
        createdAtFrom,
        createdAtTo,
        kitchens,
        diningStyles,
        dressCodes,
        sort = 'createdAt',
        sortDirection = -1,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Send the _id
         */
        city?: string,
        /**
         * Send the _id
         */
        cities?: Array<string>,
        types?: Array<string>,
        experienceType?: 'event' | 'travel',
        priceRatings?: Array<'low' | 'medium' | 'high' | 'veryHigh'>,
        /**
         * Can be used by the admin.
         */
        status?: 'pending' | 'active' | 'hidden' | 'deleted',
        isOnlyLike?: boolean,
        createdAtFrom?: string,
        createdAtTo?: string,
        kitchens?: Array<string>,
        diningStyles?: Array<string>,
        dressCodes?: Array<string>,
        sort?: 'createdAt' | 'popular' | 'name',
        sortDirection?: -1 | 1,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/venue/count',
            query: {
                'search': search,
                'city': city,
                'cities': cities,
                'types': types,
                'experienceType': experienceType,
                'priceRatings': priceRatings,
                'status': status,
                'isOnlyLike': isOnlyLike,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'kitchens': kitchens,
                'diningStyles': diningStyles,
                'dressCodes': dressCodes,
                'sort': sort,
                'sortDirection': sortDirection,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Venue
     * @returns VenueResponseDTO Venue Data
     * @throws ApiError
     */
    public getVenue({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<VenueResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/venue/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Venue not found`,
            },
        });
    }

}
