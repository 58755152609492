/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdminChangeEventPreferencesDTO } from './AdminChangeEventPreferencesDTO';

export type AdminChangeUserDTO = {
    firstName?: string;
    lastName?: string;
    /**
     * Send the _id
     */
    city?: string;
    /**
     * Required for the talent role
     */
    height?: number;
    /**
     * Required for the talent role
     */
    dressSize?: string;
    /**
     * Required for the talent role
     */
    hairColor?: string;
    eyeColor?: string;
    gender?: AdminChangeUserDTO.gender;
    nicknameInstagram?: string;
    birthDate?: string;
    email?: string;
    isFeatured?: boolean;
    isEnableScreenshots?: boolean;
    usingDesign?: AdminChangeUserDTO.usingDesign;
    isHide?: boolean;
    bookingsCancelCount?: number;
    eventPreferences?: AdminChangeEventPreferencesDTO;
};

export namespace AdminChangeUserDTO {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }

    export enum usingDesign {
        NEW = 'new',
        D1 = 'd1',
    }


}

