/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingTransactionVenueResponseDTO } from './BookingTransactionVenueResponseDTO';

export type TransactionBookingResponseDTO = {
    _id: string;
    name: string;
    eventType: TransactionBookingResponseDTO.eventType;
    type: TransactionBookingResponseDTO.type;
    startDate: string;
    endDate: string;
    venue: BookingTransactionVenueResponseDTO;
};

export namespace TransactionBookingResponseDTO {

    export enum eventType {
        ENTERTAINMENT = 'entertainment',
        DATE = 'date',
        MATCHMAKING = 'matchmaking',
    }

    export enum type {
        EVENT = 'event',
        TRAVEL = 'travel',
    }


}

