/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLuxuryServiceDTO } from '../models/CreateLuxuryServiceDTO';
import type { LuxuryServiceResponseDTO } from '../models/LuxuryServiceResponseDTO';
import type { PageLuxuryServiceResponseDTO } from '../models/PageLuxuryServiceResponseDTO';
import type { UpdateLuxuryServiceDTO } from '../models/UpdateLuxuryServiceDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LuxuryServiceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * create Luxury Service
     * @returns LuxuryServiceResponseDTO Luxury Service Data
     * @throws ApiError
     */
    public createLuxuryService({
        formData,
    }: {
        formData: CreateLuxuryServiceDTO,
    }): CancelablePromise<LuxuryServiceResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/luxury-service',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or luxuryService.addEditLuxuryService can execute the request.`,
            },
        });
    }

    /**
     * Update luxury service by Admin
     * @returns LuxuryServiceResponseDTO Luxury Service Data
     * @throws ApiError
     */
    public updateLuxuryService({
        id,
        formData,
    }: {
        /**
         * Id
         */
        id: string,
        formData: UpdateLuxuryServiceDTO,
    }): CancelablePromise<LuxuryServiceResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/luxury-service/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or luxuryService.addEditLuxuryService can execute the request.`,
            },
        });
    }

    /**
     * Luxury Service Delete
     * @returns any Luxury Service Deleted
     * @throws ApiError
     */
    public deleteLuxuryService({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/luxury-service/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `Luxury Service not found`,
            },
        });
    }

    /**
     * Luxury Service
     * @returns LuxuryServiceResponseDTO Luxury Service Data
     * @throws ApiError
     */
    public getLuxuryService({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<LuxuryServiceResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/luxury-service/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Luxury Service not found`,
            },
        });
    }

    /**
     * Luxury Service List
     * @returns PageLuxuryServiceResponseDTO List of luxury service
     * @throws ApiError
     */
    public getLuxuryServiceList({
        search,
        status,
        category,
        createdAtFrom,
        createdAtTo,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
        sort = 'order',
        sortDirection = 1,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        category?: 'restaurant' | 'villa' | 'jets' | 'yacht' | 'cars',
        createdAtFrom?: string,
        createdAtTo?: string,
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
        sort?: 'createdAt' | 'order' | 'requestCount',
        sortDirection?: -1 | 1,
    }): CancelablePromise<PageLuxuryServiceResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/luxury-service/list',
            query: {
                'search': search,
                'status': status,
                'category': category,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
                'sort': sort,
                'sortDirection': sortDirection,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Luxury Service Count
     * @returns number Luxury Service Count
     * @throws ApiError
     */
    public getLuxuryServiceCount({
        search,
        status,
        category,
        createdAtFrom,
        createdAtTo,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        category?: 'restaurant' | 'villa' | 'jets' | 'yacht' | 'cars',
        createdAtFrom?: string,
        createdAtTo?: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/luxury-service/count',
            query: {
                'search': search,
                'status': status,
                'category': category,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
