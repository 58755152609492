/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetVenueListOptionsDTO = {
    /**
     * Used to filter data by name
     */
    search?: string;
    /**
     * Send the _id
     */
    city?: string;
    /**
     * Send the _id
     */
    cities?: Array<string>;
    types?: Array<string>;
    experienceType?: GetVenueListOptionsDTO.experienceType;
    priceRatings?: Array<'low' | 'medium' | 'high' | 'veryHigh'>;
    /**
     * Can be used by the admin.
     */
    status?: GetVenueListOptionsDTO.status;
    isOnlyLike?: boolean;
    createdAtFrom?: string;
    createdAtTo?: string;
    kitchens?: Array<string>;
    diningStyles?: Array<string>;
    dressCodes?: Array<string>;
    sort?: GetVenueListOptionsDTO.sort;
    sortDirection?: GetVenueListOptionsDTO.sortDirection;
};

export namespace GetVenueListOptionsDTO {

    export enum experienceType {
        EVENT = 'event',
        TRAVEL = 'travel',
    }

    /**
     * Can be used by the admin.
     */
    export enum status {
        PENDING = 'pending',
        ACTIVE = 'active',
        HIDDEN = 'hidden',
        DELETED = 'deleted',
    }

    export enum sort {
        CREATED_AT = 'createdAt',
        POPULAR = 'popular',
        NAME = 'name',
    }

    export enum sortDirection {
        '_-1' = -1,
        '_1' = 1,
    }


}

