/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';

export type LikeBookingResponseDTO = {
    _id: string;
    name: string;
    /**
     * There is if "type = travel"
     */
    city?: CityPopulateResponseDTO;
    eventType: LikeBookingResponseDTO.eventType;
    type: LikeBookingResponseDTO.type;
    startDate: string;
    endDate: string;
};

export namespace LikeBookingResponseDTO {

    export enum eventType {
        ENTERTAINMENT = 'entertainment',
        DATE = 'date',
        MATCHMAKING = 'matchmaking',
    }

    export enum type {
        EVENT = 'event',
        TRAVEL = 'travel',
    }


}

