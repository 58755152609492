/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateLuxuryServiceDTO = {
    name: string;
    category: CreateLuxuryServiceDTO.category;
    generalCategory: CreateLuxuryServiceDTO.generalCategory;
    serviceLevels: Array<string>;
    description: string;
    email: string;
    emailBcc?: string;
    phone: string;
    discount: number;
    isDiscountWithMembership: boolean;
    status: CreateLuxuryServiceDTO.status;
    /**
     * Used to sort the list.
     */
    order: number;
    uploadPhotos?: Array<Blob>;
    uploadLogo?: Blob;
};

export namespace CreateLuxuryServiceDTO {

    export enum category {
        RESTAURANT = 'restaurant',
        VILLA = 'villa',
        JETS = 'jets',
        YACHT = 'yacht',
        CARS = 'cars',
    }

    export enum generalCategory {
        TRAVEL = 'Travel',
        ACCOMMODATION = 'Accommodation',
        TRANSPORTATION = 'Transportation',
        DINING_EVENTS = 'Dining & Events',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

